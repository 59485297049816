import * as React from "react"
import Header from "../components/header-mentor"
import Footer from "../components/footer"
import imgProfile from "../images/grega-cv.png"
import logoGreen from "../images/logo-green.png"
import ContactForm from "../components/contact-form"
import GDPRConsent from "../components/gdpr-consent"
import Seo from "../components/seo"
import PackagekOffer from "../components/package-block/grega"
import DiscountBanners from "../components/discount-banners"

function coachingCurriculum() {
  return (
    <div className="text-left px-10 py-0">
      <b>Introduction to Elliott Wave Theory</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Discovery by Ralph Nelson Elliott in the 1930s.
        </li>
        <li>
          Analysis of financial market cycles and trends through investor psychology.
        </li>
        <li>
          Description of market behaviors and fractal wave patterns.
        </li>
        <li>
          Introduction to the basic pattern of Elliott waves.
        </li>
      </ul>

      <b>Motive Waves</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Detailed explanation of motive waves, including impulse waves and diagonal triangles.
        </li>
        <li>
          Rules and guidelines
        </li>
      </ul>

      <b>Corrective Waves</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Discussion on the nature of corrective waves, including types like Zig-Zag, Flat, and Triangle patterns.
        </li>
        <li>
          Detailed rules and guidelines for identifying corrective patterns.
        </li>
        <li>
          Introduction to complex patterns like combinations (double zigzag, flat and triangle combinations).
        </li>
      </ul>

      <b>Channeling</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          How to use channels with Elliott Wave theory
        </li>
      </ul>

      <b>Fibonacci</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          The use of Fibonacci with Elliott Wave, identifying reversal and projected levels.
        </li>
      </ul>

      <b>Confirming Price Action</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          What is confirming price action, and how to identify it
        </li>
      </ul>

      <b>Where to start counting waves</b>
      <br />
      <b>Practical Applications</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Examples of Elliott Wave Theory in action.
        </li>
        <li>
          Use of other market tools in combination with Elliott Wave Theory, such as Candlesticks, Moving Averages, MACD, RSI, and volume analysis.
        </li>
        <li>
          Market Correlations
        </li>
      </ul>

      <b>Step-by-Step Approach</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          A detailed step-by-step process for applying Elliott Wave Theory, including pattern recognition, technical analysis, and setup.
        </li>
      </ul>

      <b>Trading Tips</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          10 trading tips when trading with Elliott Wave
        </li>
      </ul>

    </div>
  )
}

const IndexPage = () => {
  const mentor = {
    name: "Grega Horvat",
    firstName: "Grega",
    twitter: "@GregaHorvatFX",
    headerText: "Lost in wave counts? Wondering what is confirming a price action? Elevate your Elliott wave skills!",
    videoUrlCode: "6UuYrgLt5bM",
  }
  return (
    <>
      <Seo title='Trader Coach Grega' />
      <GDPRConsent />
      <Header props={mentor} />
      <main>
        <>
          {<DiscountBanners mentor='grega' />}
          <div className="bg-gray-900 text-white pt-8" id="bio">
            <div className="lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="flex items-end">
                <div className="">
                  <img
                    className="inline w-40 md:w-60 lg:w-80"
                  src={imgProfile}
                  alt={'Grega'}
                />
                </div>
                <div className="px-2 md:pb-6">
                  <span className="pb-3 block text-green-400 text-3xl font-bold">{mentor.name}</span>
                  <span className="block text-white md:text-3xl font-bold">Elliot Wave Analysis Expert</span>
                </div>
              </div>

              <div className="text-justify px-6 pt-6">
                Gregor Horvat is a renowned expert in financial markets, specializing in the Elliott Wave Theory. With a wealth of experience and a deep understanding of market dynamics, Gregor has achieved significant recognition in the field of technical analysis. His approach combines a thorough knowledge of market psychology with precise technical skills, offering unique insights into market forecasting and strategies.
                <br /><br />

                Gregor is also part of a team at Forex Analytix and an occasional speaker at the very popular show F.A.C.E, covering his Elliott wave analysis and opinion.
                <br />
                He also won the award on FXStreet.com for Best Forex Analysis in 2016, plus Best Buy-Side Analysis in 2018. Hey enjoys teaching and helping other clients and share his opinion about the Elliott wave principle and how to label and track unfolding patterns in real-time.
                <br /><br />

                <span className="font-bold">
                  Elliott wave is his passion!
                </span>

              </div>

              <div className="text-center">
                <img
                  className="inline h-60 w-60 lg:w-80 lg:h-80"
                  src={logoGreen}
                  alt="logo"
                />

              </div>
            </div>


          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-50 text-gray-900 py-8">
            <div className="block text-center text-xl font-bold px-2 md:text-xl">
              <div className="text-green-400 text-2xl">BOOK NOW</div>
              Buy 3 sessions of 1 hour and get $150 discount<br />
              <span className="text-green-400">OR</span><br />
              Buy 1 session of 1 hour and get 40% discount<br />
              <span className="text-xl md:text-lg font-normal">* applies one time only for your first 1 hour session</span>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-900 text-white">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-8 px-4 sm:px-6 sm:pt-16 lg:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold py-8 text-green-400">
                Coaching Curriculum
              </div>
              {coachingCurriculum()}
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <PackagekOffer />

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-6 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold mb-6 mt-6 text-white text-center">
                Get 40% off for your first 1 hour session using the coupon: <span className="text-red-600 text-4xl">40%OFF</span>
              </div>
              <div className="text-xl font-bold mt-6 text-center">
                <u>Billed at $120</u>.
              </div>
              <div className="font-normal text-lg">
                Standard price for 1 hour session $200.
              </div>

              <div className="mt-4 flex justify-center" id="booknow">
                <div className="inline-flex">
                  <a
                    href="https://TraderCoach.as.me/?appointmentType=58719736"
                    target="_blank" rel="noopener" rel="noreferrer"
                    // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
                    className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Buy 1 Session Now!
                  </a>
                </div>
              </div>

              <div className="font-normal text-lg mt-8">
                * Click on the "Redeem Coupon or Package" button to enter the coupon code.
              </div>
              <div className="mb-4"></div>
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto pt-10 pb-12 px-0 lg:px-8 h-full" id="booknow">
            <section className="bg-gray-100 overflow-hidden h-full" id="grega">
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=30981395&appointmentType=58719736" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
                <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </section>
          </div>

          <ContactForm props={{ name: mentor.firstName, twitter: mentor.twitter }} />

        </>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
